import { ActionTree, install } from "vuex";
import { fetch } from "@/services/api";
import JSZip from "jszip";
import Compressor from "compressorjs";
import router from "@/router";
import download from "downloadjs";
import {
  template,
  isObject,
  isEmpty,
  isNull,
  isNumber,
  isString,
} from "lodash";
import anime from "animejs";
import { app } from "@/main";
import Vue from "vue";

import { AxiosResponse } from "axios";

import { up } from "@/helpers";
import getPosition from "@/utils/getPosition";
import { extname } from "@/utils";
import { validation, validationFile } from "@/services";

import { products } from "../products";
import { cert } from "../cert";

import { crypto } from "./index";

import { logError, logErrorText } from "@/services/api/serverLogger";

import { InitialFileTypes } from "@/constants/InitialFileTypes";
import { ApplicationState } from "./interfaces/application-state.interface";
import { RootState } from "../../root.interface";

import {
  SET_OPTIONS_COUNTRY,
  SET_OPTIONS_REGION,
  CLEAR_FORM_CONTROLS,
  CLEAR_REGIONS,
  SET_FORM_CONTROL,
  SET_IS_FORM_VALID,
  SET_DISABLED,
  SET_FILES_DOWNLOAD,
  SET_DOCUMENT_DOWNLOAD,
  SET_TYPE_SELECTION,
  SET_ACTIVE_TAB,
  NEXT_ACTIVE_TAB,
  SET_FORM_CONTROLS_FILE,
  SET_ORDER_ID,
  SET_IS_UPLOAD_FORM,
  SET_IS_EDIT,
  SET_STATUS,
  SET_CREATION_DATE,
  SET_CHANGE_DATE,
  CLEAR_UPLOAD_FORM_CONTROLS,
  SET_IS_EDIT_UPLOAD,
  SET_IS_DOCUMENTS_COLLECTED,
  SET_IS_CERTIFICATE_REQUESTED,
  SET_CURRENT_CERT_THUMBPRINT,
  SET_IS_UPLOAD_FORM_LOADED,
  SET_CERT_IS_VALID,
  SET_ERROR_MESSAGE,
  SET_CERTIFICATE,
  SET_COMMENT,
  SET_STATUS_ID,
  SET_READONLY_FIELDS_KEY,
  SET_READONLY_FIELDS_KEY_ALL,
  RESET_READONLY_FIELDS_KEY,
  SET_EXTERNAL_SOURCE_PKCS_10,
  SET_ARCHIVED,
  SET_MANAGER_ID,
  SET_IS_SUBMITED,
  SET_OPTIONS_FILIAL,
  SET_FNS_EXISTING_CERTS,
  CLEAN_FNS_EXISTING_CERTS,
} from "@/constants/mutations-type";

import { ProductsState } from "../products/interfaces/products.state";
import { CertState } from "../cert/interfaces/cert.state";
import { DocumentsResponse } from "./interfaces/actions.interface";
import {
  IDefaultUploadFormControls,
  IFieldUpload,
} from "./interfaces/default-upload-form.interface";
import { POSITION } from "vue-toastification";
import { InitialApplicationStatuses } from "@/constants/InitialApplicationStatuses";																					

const compressorAsync = (
  file: File,
  options?: Omit<Compressor.Options, "success" | "error">
): Promise<{ name: string; blob: Blob }> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 1920,
      mimeType: "image/jpeg",
      quality: 0.6,
      success: (blob) => resolve({ name: file.name, blob }),
      error: reject,
      ...options,
    });
  });
};

const compressorBlobAsync = (
  file: Blob, 
  fileName: string,
  options?: Omit<Compressor.Options, "success" | "error">
): Promise<{ name: string; blob: Blob }> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 1920,
      mimeType: "image/jpeg",
      quality: 0.6,
      success: (blob) => resolve({ name: fileName, blob }),
      error: reject,
      ...options,
    });
  });
};

function base64toBlob(base64Url: string, contentType:string) {
  contentType = contentType || '';
  const sliceSize = 1024;
  const base64Data = base64Url.substr(base64Url.indexOf(',')+1)
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

const isFormValid = (controls: any) => {
  const errors: string[] = [];
  Object.keys(controls).forEach((key: string) => {
    const control = { ...controls[key] };
    if (
      (!control.isBlur && control.isBlur !== undefined && control.active) ||
      (control.required && !control.isValid && control.active)
    ) {
      errors.push(key);
    }
  });

  return errors.length === 0;
};

export const actions: ActionTree<ApplicationState, RootState> = {
  cryptoLoad() {
    crypto.load();
  },

  clearFromControlsHandler({ commit, dispatch }: any) {
    commit(CLEAR_FORM_CONTROLS);
    commit(CLEAR_REGIONS);
    commit(SET_TYPE_SELECTION, "");
    commit(RESET_READONLY_FIELDS_KEY);
    commit(SET_IS_SUBMITED,false);
    dispatch("clearCertRequest", null, { root: true });
  },

  clearErrorMessage({ commit }: any) {
    commit(SET_ERROR_MESSAGE, null);
  },

  setCurrentCertThumbprint({ commit }: any, val: string) {
    commit(SET_CURRENT_CERT_THUMBPRINT, val);
  },

  setActiveTab({ commit, state }: any, num: number) {
    if (num !== state.activeTab) {
      commit(SET_ACTIVE_TAB, num);
    }
  },

  nextActiveTab({ commit }: any, scrollUp: boolean = false) {
    if (scrollUp) {
      up(commit.bind(null, NEXT_ACTIVE_TAB));
    } else {
      commit(NEXT_ACTIVE_TAB);
    }
  },

  typeSelectionHandler({ commit, state, dispatch }: any, evt: any) {
    const innControl = { ...state.formControls.inn };

    innControl.value = "";
    innControl.isValid = true;
    innControl.isBlur = false;

    if (evt.target.value === "ur") {
      innControl.rules = {
        inn: true,
        empty: true,
        minLength: 10,
      };
    } else {
      innControl.rules = {
        inn: true,
        empty: true,
        minLength: 12,
      };
    }

    if (state.fieldsReadonlyKeys.length) {
      commit(CLEAR_FORM_CONTROLS);
    } else {
      commit(SET_FORM_CONTROL, { control: innControl, name: "inn" });
    }

    commit(SET_TYPE_SELECTION, evt.target.value);

    if (
      !isEmpty(state.formControls.typeEntre.value) &&
      state.isFormValid !== null &&
      !state.isFormValid
    ) {
      dispatch("checkValidity");
    }
  },

  edit({ commit, state }: any) {
    if (state.activeTab === 1 || state.activeTab === 2) {
      commit(SET_IS_EDIT, true);
      commit(SET_DISABLED, false);
      commit(SET_DOCUMENT_DOWNLOAD, false);
      commit(SET_IS_FORM_VALID, true);
    }

    commit(SET_IS_SUBMITED,false);
    commit(SET_IS_DOCUMENTS_COLLECTED, false);
    commit(SET_IS_EDIT_UPLOAD, true);
    commit(CLEAR_UPLOAD_FORM_CONTROLS);
    commit(SET_IS_CERTIFICATE_REQUESTED, false);
  },

  inputHandler({ commit, state }: any, evt: any) {
    const { name, checked, type, value } = evt.target;
    const control = { ...state.formControls[name] };
    const isCheckbox = type === "checkbox";

    if((evt.type==="change" && name.startsWith("filial")) 
      || (evt.type==="change" && name.startsWith("region")) 
      || !name.startsWith("region")){
      control.value = isCheckbox ? checked : value;

      if (typeof control.value === "string") {
        control.value = value.replace(/\s+/g, " ").replace(/^\s/, "");
      }
    }
    
    control.isTouched = true;

    if (
      (evt.type === "blur" || control.isBlur) &&
      !control.checkboxId &&
      isFormValid !== null
    ) {
      control.isBlur = true;
      control.isValid = validation(control.value, control.rules);
    }

    commit(SET_FORM_CONTROL, { control, name });

    if (!state.isFormValid) {
      commit(SET_IS_FORM_VALID, isFormValid(state.formControls));
    }
  },

  checkValidity({ commit, state }: any) {
    Object.keys(state.formControls).forEach((name: string) => {
      if (state.formControls[name].active && name !== "typeEntre") {
        const control = { ...state.formControls[name] };
        control.isTouched = true;
        control.isBlur = true;
        control.isValid = validation(control.value, control.rules);
        commit(SET_FORM_CONTROL, { control, name });
      }
    });

    commit(SET_IS_FORM_VALID, isFormValid(state.formControls));

    setTimeout(() => {
      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement;
      const position: number[] = [];

      document.querySelectorAll(".form-item.invalid").forEach((elem) => {
        position.push(getPosition(elem).top);
      });

      anime({
        targets: scrollElement,
        scrollTop: Math.min.apply(null, position),
        duration: 800,
        easing: "easeInOutQuad",
      });
    }, 50);
  },

    
  async getReferenceCountries({ commit }) {
    try {
      const res = await fetch.post(`api/InitialApplication/getCountries`);

      if (res.status === 200) {
        commit(SET_OPTIONS_COUNTRY, res.data.countries);
      }
    } catch (error) {
      logError(error, `Ошибка получения списка стран`);
      throw error;
    }
  },

  async getReferenceRegions({ commit }) {
    try {
      const res = await fetch.post(`api/InitialApplication/getRegions`);

      if (res.status === 200) {
        commit(SET_OPTIONS_REGION, res.data.regions);
      }
    } catch (error) {
      logError(error.message, `Ошибка получения списка регионов`);
      throw error;
    }
  },

  async getReferenceFilials({ commit }) {
    try {
      const res = await fetch.post(`api/InternalPreApplication/partner/getFilials`);

      if (res.status === 200) {
        commit(SET_OPTIONS_FILIAL, res.data.filials);
      }
    } catch (error) {
      logError(error.message, `Ошибка получения списка регионов`);
      throw error;
    }
  },

   

  async getApplication({ commit, dispatch, state }: any, id: number | string) {
    if (app) {
      (app as any).$modal.show("loader");
    }
    try {
      const res = await fetch.get(`api/InternalPreApplication/partner/${id}`);
      if (app) {
        (app as any).$modal.hide("loader");
      }

      if (res.status === 200) {
        const {
          status,
          statusId,
          managerId,
          creationDate,
          comment,
          filials,
          fnsExistingCerts
        } = res.data;

        dispatch(
          "initProducts",
          {
            products: res.data.products,
            productsSelected: res.data.application.products,
          },
          { root: true }
        );

        Object.keys(state.formControls).forEach((nameControl) => {
          const control = { ...state.formControls[nameControl] };

          if (nameControl !== "typeEntre") {
            const val: any = res.data.application[nameControl];

            if (isObject(val)) {
              Object.keys(val).forEach((name) => {
                if (control.hasOwnProperty(name)) {
                  control[name] = (val as any)[name];
                }
              });
            } else {
              control.value = val;
            }

            control.disabled = true;
            control.isValid = true;
            control.isBlur = true;
            commit(SET_FORM_CONTROL, { control, name: nameControl });
          }
        });

        
        commit(SET_IS_EDIT, false);
        commit(SET_IS_FORM_VALID, true);
        commit(SET_ORDER_ID, id);
        commit(SET_STATUS, status);
        commit(SET_STATUS_ID, statusId);
        commit(SET_MANAGER_ID, managerId);
        if(filials)
        commit(SET_OPTIONS_FILIAL, filials);  
        commit(SET_COMMENT, comment);
        commit(SET_TYPE_SELECTION, res.data.application.typeEntre);
        commit(SET_CREATION_DATE, creationDate);
        commit(SET_IS_SUBMITED,true);
        commit(SET_FNS_EXISTING_CERTS, fnsExistingCerts || []);
      }
    } catch (err) {
      if (err.response.status === 403) {
        router.push("/403");
      } else {
        throw err;
      }
    }
  },

  async submitHandler({ commit, state, dispatch }: any) {
    dispatch("checkValidity");

    if (state.isFormValid) {
      const body: any = {
        orderId: Number(state.orderId),
        products: await dispatch("getProductsSelected", null, { root: true }),
      };

      Object.keys(state.formControls).forEach((nameControl) => {
        const control = { ...state.formControls[nameControl] };
        const val = control.value;
        if (nameControl === "typeEntre") {
          body[nameControl] =
            (val === "fl" && 1) || (val === "ip" && 2) || (val === "ur" && 3);
        } else if (control.active) {
          body[nameControl] = typeof val === "string" ? val.trim() : val;
        }
      });

      try {
        commit(SET_IS_SUBMITED,true);
        const res = await fetch.post("api/InternalPreApplication/partner", body);

        if (res.status === 200) {
          const { result, orderId, statusId } = res.data;

          if(!res.data.hasOwnProperty("error")){
            if (result) {
              commit(SET_ORDER_ID, orderId);
              // commit(SET_MANAGER_ID, managerId);
              commit(SET_STATUS_ID, statusId);
              commit(SET_DISABLED, true);
              // if (files.length) {
              //   commit(SET_FILES_DOWNLOAD, files);
              //   commit(SET_DOCUMENT_DOWNLOAD, true);
                commit(SET_IS_EDIT, false);
              //   await up(commit.bind(null, NEXT_ACTIVE_TAB));
              // }

              router.push(`/application/${orderId}`);
            } else {
              throw new Error("Что-то, пошло не так");
            }
          } else {
            // throw new Error(res.data.error);  
            commit(SET_IS_SUBMITED,false);
            let mess = `Ошибка при сохранении предзаявки ${state.orderId}: ${res.data.error}`;
            
            Vue.$toast.error(mess, 
              {
              position: POSITION.TOP_LEFT,
              timeout: 7500,
              closeOnClick: false,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: false,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false
            });
          }
        }
      } catch (err) {
        commit(SET_IS_SUBMITED,false);
        logError(err, `Ошибка при сохранении предзаявки ${state.orderId}`);
        let mess = `Ошибка при сохранении предзаявки ${state.orderId}`;

        if (err.data && err.data.message) {
          mess = err.data.message;
        }
        Vue.$toast.error(mess, {
          position: POSITION.TOP_LEFT,
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    }
  },

  
};
