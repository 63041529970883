import { Module } from "vuex";

import { actions } from "./cert.actions";
import { mutations } from "./cert.mutations";

import { CertState } from "./interfaces/cert.state";
import { RootState } from "@/store/root.interface";

const namespaced: boolean = true;

export const cert: Module<CertState, RootState> = {
  namespaced,
  state: {
    cert: null
  },
  mutations,
  actions
};
