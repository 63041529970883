import { ActionTree } from "vuex";
import { notify } from "../services/notify";

import { RootState } from "./root.interface";
import { SET_REGISTRATION, SET_UPDATE_EXISTS } from "./root.constants";

export const actions: ActionTree<RootState, RootState> = {
  preUpdateApp({ commit }, payload: ServiceWorkerRegistration | null) {
    if (payload) {
      commit(SET_REGISTRATION, payload);
    }

    commit(SET_UPDATE_EXISTS, true);
  },

  refreshApp({ state, commit }) {
    commit(SET_UPDATE_EXISTS, false);

    if (!state.registration || !state.registration.waiting) {
      notify.show({
        title: "Доступен новый контент. Пожалуйста, обновите 'Ctrl + F5'"
      });

      return;
    }

    state.registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }
};
