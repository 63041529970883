import { IFilter } from "./interfaces/filter.interface";

export const defaultFilters: IFilter = {
  fl: false,
  ip: false,
  ur: false,
  search: "",
  statusNew: false,
  statusAppFormed: false,
  // statusRelease: false,
  // statusDone: false,
  // statusFail: false,
  dateCreateFrom: "",
  dateCreateTo: "",
  dateIssueFrom: "",
  dateIssueTo: ""
};
