import { GetterTree } from "vuex";
import { isEmpty } from "lodash";
import { numberFormat } from "@/helpers/numberFormat";

import { ProductsState, Product } from "./interfaces/products.state";
import { RootState } from "@/store/root.interface";

import { application } from "../application";

export const getters: GetterTree<ProductsState, RootState> = {
  getPrice(state) {
    return (price: { [key: string]: string }) => {
      const typeEntre = (application as any).state.formControls.typeEntre.value;

      if (!typeEntre && isEmpty(typeEntre) && !price[typeEntre]) {
        return "";
      }

      return `${numberFormat(Number(price[typeEntre]))} ₽`;
    };
  },

  isAvailable(state) {
    return (price: { [key: string]: string }) => {
      const typeEntre = (application as any).state.formControls.typeEntre.value;

      if (!typeEntre && isEmpty(typeEntre) && !price[typeEntre]) {
        return false;
      }

      return Number(price[typeEntre])>-1;
    };
  },

  getResultPrice(store) {
    const typeEntre = (application as any).state.formControls.typeEntre.value;

    return store.productsSelected.reduce((sum, id) => {
      const prod: Product | undefined = store.products.find(
        product => product.id === id
      );

      if (prod && prod.price && !isEmpty(typeEntre)) {
        if(Number(prod.price[typeEntre])>-1)
          return sum + Number(prod.price[typeEntre]);
      }

      return sum;
    }, 0);
  }
};
