




























































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "Spiner" })
export default class Spiner extends Vue {
  @Prop({ type: String, default: "#6f61e9" })
  public readonly color!: string;
  @Prop({ type: String, default: "1000ms" })
  public readonly duration!: string;
  @Prop({ type: [Number, String], default: 30 })
  public readonly width!: string | number;
  @Prop({ type: [Number, String], default: 30 })
  public readonly height!: string | number;
}
