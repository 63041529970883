export const extname = (name: string) => {
  const nameArr = name.trim().split(".");

  return `.${nameArr[nameArr.length - 1]}`;
};

export const genId = (): string => {
  return Math.pow((new Date().getTime() * Math.random()) ^ 0, 2).toString(32);
};

export const isEmpty = (str: string) => str.toString().trim() === "";
