import { GetterTree } from "vuex";
import moment from "moment";
import { isEqual, isEmpty } from "lodash";

import { ApplicationList } from "./interfaces/application-list.interface";
import { RootState } from "../../root.interface";

import { defaultFilters } from "./default-filters";

export const getters: GetterTree<ApplicationList, RootState> = {
  getApplicationList(state) {
    return state.applicationsList.map((el: any) => {
      return [
        { id: el.id, externalSourcePkcs10: el.externalSourcePkcs10 },
        el.creationDate,
        { status: el.status, comment: isEmpty(el.managerComment) ? null : el.managerComment },
        {
          organizationShortName: el.organizationShortName || "",
          fnsStatusId: el.fnsStatusId || 0
        },
        `${el.surname} ${el.name} ${el.middleName}`,
        el.applicantType,
        el.phoneNumber,
        el.order,
        el.totalPrice=="-1"?"нет доступных продуктов":`<b>${el.totalPrice} ₽</b>`
      ];
    });
  },

  getFilter(state): string {
    const result: string[] = [];

    Object.keys(state.saveFilterControls).forEach(name => {
      let val = state.saveFilterControls[name];

      if (val || (typeof val === "string" && !isEmpty(val))) {
        if (val instanceof Date) {
          val = moment(val)
            .locale("ru")
            .format("L");
        }
        result.push(`${name}=${val}`);
      }
    });

    if (state.isArchive) {
      result.push("statusArchive=true");
    }

    return result.length ? "?" + result.join("&") : "";
  },

  isActiveFilter(state) {
    return !isEqual(state.saveFilterControls, defaultFilters);
  }
};
