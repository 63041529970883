import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import profile from "./modules/profile";
import { application } from "./modules/application";
import { applicationList } from "./modules/application-list";
import { authorization } from "./modules/authorization";
import { products } from "./modules/products";
import { cert } from "./modules/cert";

import { RootState } from "./root.interface";
import { actions } from "./root.actions";
import { mutations } from "./root.mutations";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  strict: process.env.NODE_ENV !== "production",
  state: {
    updateExists: false,
    registration: null
  },
  actions,
  mutations,
  modules: {
    authorization,
    application,
    applicationList,
    profile,
    products,
    cert
  }
};

export default new Vuex.Store<RootState>(store);
