export const SET_FORM_CONTROL = "SET_FORM_CONTROL";
export const SET_IS_FORM_VALID = "SET_IS_FORM_VALID";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_SAVE_USER = "SET_SAVE_USER";
export const SET_OPTIONS_COUNTRY = "SET_OPTIONS_COUNTRY";
export const SET_OPTIONS_REGION = "SET_OPTIONS_REGION";
export const CLEAR_FORM_CONTROLS = "CLEAR_FORM_CONTROLS";
export const CLEAR_REGIONS = "CLEAR_REGIONS";
export const CLEAR_UPLOAD_FORM_CONTROLS = "CLEAR_UPLOAD_FORM_CONTROLS";
export const SET_FORM_CONTROLS_FILE = "SET_FORM_CONTROLS_FILE";
export const SET_CERT_REQUEST_FILE = "SET_CERT_REQUEST_FILE";
export const SET_IS_EDIT_UPLOAD = "SET_IS_EDIT_UPLOAD";
export const SET_DISABLED = "SET_DISABLED";
export const SET_FILES_DOWNLOAD = "SET_FILES_DOWNLOAD";
export const SET_DOCUMENT_DOWNLOAD = "SET_DOCUMENT_DOWNLOAD";
export const SET_TYPE_SELECTION = "SET_TYPE_SELECTION";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const NEXT_ACTIVE_TAB = "NEXT_ACTIVE_TAB";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_IS_UPLOAD_FORM = "SET_IS_UPLOAD_FORM";
export const SET_IS_EDIT = "SET_IS_EDIT";
export const SET_IS_SUBMITED = "SET_IS_SUBMITED";
export const SET_STATUS = "SET_STATUS";
export const SET_STATUS_ID = "SET_STATUS_ID";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_IS_UPLOAD_FORM_LOADED = "isUploadFormLoaded";
export const SET_CREATION_DATE = "SET_CREATION_DATE";
export const SET_CHANGE_DATE = "SET_CHANGE_DATE";
export const SET_IS_DOCUMENTS_COLLECTED = "SET_IS_DOCUMENTS_COLLECTED";
export const SET_IS_CERTIFICATE_REQUESTED = "SET_IS_CERTIFICATE_REQUESTED";
export const SET_CERTIFICATE = "SET_CERTIFICATE";
export const SET_COMMENT = "SET_COMMENT";
export const SET_IS_SUBMIT_ACTIVE = "SET_IS_SUBMIT_ACTIVE";
export const SET_FILTER_CONTROL = "SET_FILTER_CONTROL";
export const SET_SAVE_FILTER_CONTROL = "SET_SAVE_FILTER_CONTROL";
export const SET_IS_OPEN_FILTER = "SET_IS_OPEN_FILTER";
export const SET_ARCHIVE = "SET_ARCHIVE";
export const SET_READONLY_FIELDS_KEY = "SET_READONLY_FIELDS_KEY";
export const SET_READONLY_FIELDS_KEY_ALL = "SET_READONLY_FIELDS_KEY_ALL";
export const RESET_READONLY_FIELDS_KEY = "RESET_READONLY_FIELDS_KEY";

export const SET_PROFILE = "SET_PROFILE";
export const SET_IS_OPEN = "SET_IS_OPEN";
export const SET_MANAGER_ID = "SET_MANAGER_ID";

export const SET_CERT_IS_VALID = "SET_CERT_IS_VALID";

export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const SET_SIZE_PAGES = "SET_SIZE_PAGES";
export const SET_APPLICATIONS_LIST = "SET_APPLICATIONS_LIST";
export const SET_IS_LOADED = "SET_IS_LOADED";
export const CLEAR_APPLICATION_LIST = "CLEAR_APPLICATION_LIST";

export const SET_CURRENT_CERT_THUMBPRINT = "SET_CURRENT_CERT_THUMBPRINT";

export const TOGGLE_RECOVERY_PASSWORD = "TOGGLE_RECOVERY_PASSWORD";
export const SET_IS_SAME_PASSWORD = "SET_IS_SAME_PASSWORD";
export const SET_IS_CHANGE_PASSWORD_FORM_VALID =
  "SET_IS_CHANGE_PASSWORD_FORM_VALID";
export const SET_ERROR = "SET_ERROR";
export const SET_ARCHIVED = "SET_ARCHIVED";
export const SET_EXTERNAL_SOURCE_PKCS_10 = "SET_EXTERNAL_SOURCE_PKCS_10";

export const SET_OPTIONS_FILIAL = "SET_OPTIONS_FILIAL";
export const SET_FNS_EXISTING_CERTS = "SET_FNS_EXISTING_CERTS";
export const CLEAN_FNS_EXISTING_CERTS = "CLEAN_FNS_EXISTING_CERTS";
