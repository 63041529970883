import { IDefaultFormControls } from "./interfaces/default-form.interface";

export const defaultFormControls: IDefaultFormControls = {
  typeEntre: {
    value: "",
    active: true,
    disabled: false
  },
  surname: {
    value: "",
    active: true,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  name: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  patronymic: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false
  },
  gender: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  birthDate: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      date: true
    }
  },
  dateDoc: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      date: true
    }
  },
  serialDoc: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 10
    }
  },
  passportDivision: {
    value: "",
    active: false,
    disabled: false,
    isTouched: true,
    isValid: true,
    isBlur: true,
    required: false,
    
  },
  passportDivisionCode: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 6
    }
  },
  inn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      inn: true,
      empty: true,
      minLength: 10
    }
  },
  personInn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      inn: true,
      empty: true,
      minLength: 10
    }
  },
  snils: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 11
    }
  },
  ogrnip: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      ogrnip: true,
      empty: true,
      minLength: 15
    }
  },
  position: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  department: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false
  },
  ogrn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      ogrn: true,
      empty: true,
      minLength: 13
    }
  },
  countryId: {
    value: 193,
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  regionRealId: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  cityReal: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  addressReal: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false
  },
  regionLawId: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  cityLaw: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  addressLaw: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  email: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      email: true
    }
  },
  phone: {
    value: "",
    active: false,
    isTouched: false,
    disabled: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 10
    }
  },
  addovert: {
    value: 1,
    active: false,
    disabled: false
  },
  kpp: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 9
    }
  },
  index: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 6
    }
  },
  // TO-DO: убрать ФИО организации
  surnameOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  nameOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  patronymicOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: true,
    required: false
  },
  ///
  phoneOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 10
    }
  },
  fullNameOrg: {
    value: "",
    active: false,
    isTouched: false,
    disabled: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  shortNameOrg: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  // TO-DO: убрать должность директора
  positionDirector: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  ///
  filialId: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
};
