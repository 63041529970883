













import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Loader extends Vue {
  @Prop({ type: Boolean, default: false }) public isLoading!: boolean;
  @Prop({ type: String }) public message!: string;
}
