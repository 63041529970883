import { fetch, setAccessToken } from "@/services/api";
import { ActionTree } from "vuex";
import cookies from "js-cookie";
import router from "@/router";

import { BASE_URL_AUTH } from "@/constants";

import { validation, isFormValid } from "@/services";
import { app } from "@/main";

import { RootState } from "@/store/root.interface";
import { AuthorizationState } from "./interfaces/authorization.state";
import { IRulesValidation } from "@/services/validation";

import { defaultFormControls } from "./login.default";

import {
  CLEAR_FORM_CONTROLS,
  SET_ERROR_MESSAGE,
  SET_FORM_CONTROL,
  SET_IS_FORM_VALID,
  SET_IS_SUBMIT_ACTIVE,
  SET_SAVE_USER,
  TOGGLE_RECOVERY_PASSWORD
} from "./constants/mutations-type";

export const actions: ActionTree<AuthorizationState, RootState> = {
  toggleRecoveryPassword({ commit }) {
    commit(TOGGLE_RECOVERY_PASSWORD);
    commit(SET_ERROR_MESSAGE, null);
  },

  onSaveUser({ commit }, val: any) {
    commit(SET_SAVE_USER, val.target.checked);
  },

  inputHandler({ commit, state }, evt) {
    if (!state.isSubmitActive) {
      const control = { ...state.formControls[evt.target.name] };

      control.value = evt.target.value;
      control.isTouched = true;

      if (evt.type === "blur" || control.isBlur) {
        control.isBlur = true;
        control.isValid = validation(
          control.value,
          control.rules as IRulesValidation
        );
      }

      commit(SET_FORM_CONTROL, { control, name: evt.target.name });

      if ((control.isBlur && !control.isValid) || !state.isFormValid) {
        const formControls = state.isRecoveryPassword
          ? { email: state.formControls.email }
          : state.formControls;
        commit(SET_IS_FORM_VALID, isFormValid(formControls));
      }
    }
  },

  clear({ commit, state }) {
    commit(CLEAR_FORM_CONTROLS);

    Object.keys(state.formControls).forEach((name: string) => {
      commit(SET_FORM_CONTROL, {
        name,
        control: { ...(defaultFormControls as any)[name] }
      });
    });
  },

  checkValidity({ commit, state }) {
    Object.keys(state.formControls).forEach((name: string) => {
      const control = { ...state.formControls[name] };
      control.isTouched = true;
      control.isBlur = true;
      control.isValid = validation(
        control.value,
        control.rules as IRulesValidation
      );

      commit(SET_FORM_CONTROL, { control, name });
    });

    const formControls = state.isRecoveryPassword
      ? { email: state.formControls.email }
      : state.formControls;

    commit(SET_IS_FORM_VALID, isFormValid(formControls));
  },

  async recoveryPassword({ commit, state, dispatch }) {
    commit(SET_IS_SUBMIT_ACTIVE, true);
    dispatch("checkValidity");

    if (state.isFormValid) {
      try {
        const {
          status,
          data
        } = await fetch.post(
          `${BASE_URL_AUTH}api/partnerAuth/recovery`,
          JSON.stringify(state.formControls.email.value),
          { headers: { "Content-Type": "application/json" } }
        );

        if (status === 200) {
          const { error, result } = data;

          if (result) {
            (app as any).$modal.show("dialog", {
              title: "Восстановление пароля",
              text: `Пароль отправлен вам на почту <b>${state.formControls.email.value}</b>`,
              buttons: [
                {
                  title: "ПРОДОЛЖИТЬ",
                  default: true,
                  handler: () => {
                    dispatch("toggleRecoveryPassword");
                    (app as any).$modal.hide("dialog");
                  }
                }
              ]
            });
          }

          if (error) {
            commit(SET_ERROR_MESSAGE, error);
          } else {
            commit(SET_ERROR_MESSAGE, null);
          }
        }
      } catch (error) {
        throw error;
      }
    }
    commit(SET_IS_SUBMIT_ACTIVE, false);
  },

  async submit({ commit, state, dispatch }) {
    dispatch("checkValidity");

    if (state.isFormValid) {
      commit(SET_IS_SUBMIT_ACTIVE, true);

      const body = {
        login: state.formControls.email.value,
        password: state.formControls.password.value,
        audience: location.host
      };

      try {
        const { status, data } = await fetch.post(
          `${BASE_URL_AUTH}api/partnerAuth/login`,
          body
        );

        if (status === 200) {
          const { access_token, refresh_token, expires_in } = data;

          cookies.set("_rt", refresh_token);
          setAccessToken(access_token, expires_in);
          commit(SET_ERROR_MESSAGE, null);
          router.push({ name: "home" });
          dispatch("clear");
        }
      } catch (error) {
        commit(SET_ERROR_MESSAGE, "Ошибка авторизации");

        throw error;
      } finally {
        commit(SET_IS_SUBMIT_ACTIVE, false);
      }
    }
  }
};
