import { MutationTree } from "vuex";
import { ProductsState, Product } from "./interfaces/products.state";

import {
  SET_PRODUCTS,
  SET_PRODUCTS_SELECTED,
  REMOVE_PRODUCTS_SELECTED,
  SET_PRODUCTS_SELECTED_ALL,
  RESET_PRODUCT
} from "./constants/mutations-type";

export const mutations: MutationTree<ProductsState> = {
  [SET_PRODUCTS](state, payload: Product[]) {
    state.products = [...payload];
  },
  [SET_PRODUCTS_SELECTED](state, payload: number) {
    const productsSelected = [...state.productsSelected, payload];
    state.productsSelected = [...new Set(productsSelected)];
  },
  [SET_PRODUCTS_SELECTED_ALL](state, payload: number[]) {
    const productsSelected = [...state.productsSelected, ...payload];
    state.productsSelected = [...productsSelected];
  },
  [REMOVE_PRODUCTS_SELECTED](state, payload: number) {
    state.productsSelected = state.productsSelected.filter(
      id => payload !== id
    );
  },
  [RESET_PRODUCT](state) {
    state.products = [];
    state.productsSelected = [];
    state.isValid = true;
  }
};
