import EventEmitter from "../core/event-emitter";
import Timer from "../modules/timer";

import {
  IDefaultOptions,
  NotifyType,
  AnimateIn,
  AnimateOut
} from "../defaults";
import { isColor, generateId } from "../utils/string";

import template from "../utils/template";
import { styleСonversionSides } from "../utils";

import {
  TIME_STOP,
  TIME_START,
  TIME_END,
  MOUNT_AFTER,
  MOUNT_BEFORE,
  UNMOUNT_AFTER,
  UNMOUNT_BEFORE
} from "../constants/events";

import "../scss/keyframes.scss";

export interface INotify {
  title: string;
  message?: string;
  type?: NotifyType | string;
  duration?: number;
}

export interface IGenTypeAnimation {
  animateIn: AnimateIn;
  animateOut: AnimateOut;
}

export default class Notify {
  private readonly ID: string;
  private target: HTMLDivElement | null;
  private active: boolean;
  private timer: Timer;
  private _event: EventEmitter;

  constructor(
    public notify: INotify,
    private options: IDefaultOptions,
    private ctx: any
  ) {
    this.ID = generateId();
    this.target = null;
    this.active = false;
    this.timer = new Timer(this.notify.duration || this.options.duration);
    this._event = new EventEmitter();
  }

  public get id() {
    return this.ID;
  }

  public get isActive() {
    return this.active;
  }

  public mount(elem: HTMLDivElement) {
    this._event.emit(MOUNT_AFTER, this);

    elem[this.addNotify](this.createNotify());

    setTimeout(() => {
      this.active = true;
      this.timer.start();
    }, 50);

    if (this.target && this.options.animation) {
      this.target.classList.add("animated");
      this.target.classList.add(this.options.animationOptions.animateIn);
    }

    this._event.emit(MOUNT_BEFORE, this);
  }

  public unmount() {
    this._event.emit(UNMOUNT_AFTER, this);

    this.active = false;
    if (this.target && this.options.animation) {
      this.target.classList.add("animated");
      this.target.classList.add(this.options.animationOptions.animateOut);
    } else {
      this.remove();
    }

    this._event.emit(UNMOUNT_BEFORE, this);
  }

  public on(event: string, ctx: any) {
    this._event.on(event, ctx);
  }

  private remove() {
    if (this.target && !this.active) {
      this.target.remove();
    }
    this.ctx.remove(this.ID);
  }

  private progressBar(evt: any) {
    if (this.target) {
      const progress: HTMLDivElement | null = this.target.querySelector(
        "." + this.options.className.progress
      );

      if (evt.type === TIME_STOP && progress) {
        progress.style.width = this.timer.getRemainingPercent + "%";
      }

      if (evt.type === TIME_START && progress) {
        progress.style.width = "0%";
        progress.style.transition = `width ${this.timer.getRemaining}ms ${
          this.options.progressOptions.easing
        }`;
      }
    }
  }

  private animateEnd() {
    const wrapClassList = this.target!.classList;
    const { animateIn, animateOut } = this.options.animationOptions;
    if (wrapClassList.contains(animateIn)) {
      wrapClassList.remove("animated");
      wrapClassList.remove(animateIn);
    }
    if (wrapClassList.contains(animateOut)) {
      this.remove();
    }
  }

  private get addNotify() {
    const posY = this.options.position[1];
    const addNotify = this.options.addNotify;

    if (!addNotify || addNotify === "auto") {
      return posY === "top" ? "append" : "prepend";
    }

    return addNotify;
  }

  private hover(evt: MouseEvent): void | boolean {
    if (evt.type === "mouseenter") {
      this.timer.stop();
    }
    if (evt.type === "mouseleave") {
      this.timer.start();
    }
  }

  private get color() {
    const types = this.options.styles.typeStyle;
    const type = this.notify.type! || this.options.type;
    const result = isColor(type) ? type : (types as any)[type];

    if (result && typeof result === "string" && isColor(result)) {
      return result;
    } else {
      throw new TypeError(
        'In "type" you can pass either one of the ready types ["success", "warning" "error", "info"] or specify the color;'
      );
    }
  }

  private createNotify(): HTMLDivElement {
    const { main } = this.options.className;
    const animationDuration =
      this.options.animationOptions.duration === "string"
        ? this.options.animationOptions.duration
        : this.options.animationOptions.duration + "ms";

    this.target = document.createElement("div");
    this.target.className = main;
    this.target.id = this.ID;

    this.target.style.cssText = `
      position: relative;
      box-sizing: border-box;
      box-shadow: ${this.options.styles.boxShadow};
      padding: ${styleСonversionSides(this.options.styles.padding)};
      border-radius: ${styleСonversionSides(this.options.styles.borderRadius)};
      margin: ${styleСonversionSides(this.options.styles.margin)};
      background: ${this.color};
      text-align: ${this.options.styles.textAlign};
      -webkit-animation-duration: ${animationDuration};
      animation-duration: ${animationDuration};
      overflow: hidden;
    `;

    this.options.templates.forEach(templateElem => {
      const { name, tpl, className } = templateElem;
      const text = (this.notify as any)[name];

      if ((this.notify as any)[name] && this.target) {
        this.target.innerHTML += template(tpl, {
          [name]: text,
          class: className || name
        });
      }
    }, this);

    if (this.options.progress) {
      const progress = document.createElement("div");

      progress.className = this.options.className.progress;
      progress.style.cssText = `
        position: absolute;
        ${this.options.progressOptions.position}: 0;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: ${
          isColor(this.options.progressOptions.color)
            ? this.options.progressOptions.color
            : "#737373"
        };
      `;

      this.target.prepend(progress);
      this.timer.on([TIME_START, TIME_STOP], this.progressBar.bind(this));
    }

    this.timer.on(TIME_END, this.unmount.bind(this));
    this.target.addEventListener("mouseenter", this.hover.bind(this));
    this.target.addEventListener("mouseleave", this.hover.bind(this));
    this.target.addEventListener("animationend", this.animateEnd.bind(this));
    this.target.addEventListener("click", this.unmount.bind(this));

    return this.target;
  }
}
