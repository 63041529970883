import { MutationTree } from "vuex";
import { CertState } from "./interfaces/cert.state";

import {
  SET_CERT_REQUEST_FILE,
  CLEAR_CERT_REQUEST_FILE
} from "./constants/mutations-type";

export const mutations: MutationTree<CertState> = {
  [SET_CERT_REQUEST_FILE](state, cert: string) {
    state.cert = cert;
  },
  [CLEAR_CERT_REQUEST_FILE](state) {
    state.cert = null;
  }
};
