var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'form-item',
    ("form-item-" + _vm.stype),
    {
      'form-item-password': _vm.type === 'password',
      valid: _vm.valid,
      invalid: _vm.isValidation(_vm.$props),
      'password-show': _vm.isPasswordView
    }
  ]},[(_vm.label)?_c('label',{attrs:{"for":_vm.inputID}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"input-wrap"},[_c('input',{ref:"input",attrs:{"disabled":_vm.disabled,"id":_vm.inputID,"placeholder":_vm.placeholder,"name":_vm.name,"type":_vm.inputType},domProps:{"value":_vm.value},on:{"input":_vm.inputHandler,"blur":function($event){return _vm.$emit('blur', $event)},"change":function($event){return _vm.$emit('change', $event)},"keyup":function($event){return _vm.$emit('keyup', $event)}}}),(_vm.info)?_c('span',{staticClass:"input-wrap__tooltip"},[_vm._v(_vm._s(_vm.info))]):_vm._e(),(_vm.type === 'password')?_c('div',{staticClass:"form-item-eye",on:{"mousedown":function($event){$event.preventDefault();_vm.isPasswordView = true},"mouseup":function($event){$event.preventDefault();_vm.isPasswordView = false},"mouseout":function($event){_vm.isPasswordView = false}}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }