export type NotifyPositionX = "center" | "right" | "left";
export type NotifyPositionY = "top" | "bottom";
export type NotifyType = "success" | "warning" | "error" | "info";
export type AddNotify = "auto" | "append" | "prepend";
export type StyleSides = [number, number?, number?, number?] | number | string;
export type Timing = "linear" | "ease" | "ease-in" | "ease-out" | "ease-in-out";

export type AnimateIn =
  | "fadeIn"
  | "fadeInLeft"
  | "fadeInRight"
  | "fadeInUp"
  | "fadeInDown";
export type AnimateOut =
  | "fadeOut"
  | "fadeOutLeft"
  | "fadeOutRight"
  | "fadeOutUp"
  | "fadeOutDown";

export interface ITemplate {
  name: string;
  tpl: string;
  className: string;
}

export interface IDefaultOptions {
  type: NotifyType | string;
  progress: boolean;
  once: boolean;
  progressOptions: {
    position: "top" | "bottom";
    color: string;
    easing: Timing;
  };
  position: [NotifyPositionX, NotifyPositionY];
  duration: number;
  animation: boolean;
  animationOptions: {
    duration: number | string;
    animateIn: AnimateIn | string;
    animateOut: AnimateOut | string;
  };
  addNotify: AddNotify;
  styles: {
    width: number | string;
    zIndex: number;
    boxShadow: string;
    margin: StyleSides;
    padding: StyleSides;
    borderRadius: StyleSides;
    textAlign: "left" | "right" | "center";
    typeStyle: {
      success: string;
      warning: string;
      error: string;
      info: string;
    };
  };
  className: {
    wrap: string;
    main: string;
    progress: string;
  };
  templates: ITemplate[];
}

const defaultOptions: IDefaultOptions = {
  type: "info",
  once: true,
  position: ["right", "bottom"],
  duration: 5000,
  progress: true,
  progressOptions: {
    position: "top",
    color: "#737373",
    easing: "linear"
  },
  animation: true,
  animationOptions: {
    duration: 1000,
    animateIn: "fadeIn",
    animateOut: "fadeOut"
  },
  addNotify: "auto",
  styles: {
    width: 300,
    textAlign: "center",
    boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.10)",
    margin: 15,
    padding: [15, 10],
    borderRadius: 3,
    zIndex: 999,
    typeStyle: {
      success: "#dff0d8",
      warning: "#fcf8e3",
      error: "#f2dede",
      info: "#d9edf7"
    }
  },
  className: {
    main: "notify",
    wrap: "notify-wrapper",
    progress: "notify__progress"
  },
  templates: [
    {
      name: "title",
      className: "notify__title",
      tpl: `
        <div class="{% class %}">
          <b>{% title %}</b>
        </div>
      `
    },
    {
      name: "message",
      className: "notify__message",
      tpl: `
        <div class="{% class %}">
          <span>{% message %}</span>
        </div>
      `
    }
  ]
};

export default defaultOptions;
