import Notifications from "../lib/notifications";

export const notify = new Notifications({
  type: "warning",
  duration: 8000,
  position: ["center", "top"],
  animationOptions: {
    animateIn: "fadeInDown",
    animateOut: "fadeOutUp"
  },
  styles: {
    width: "max",
    margin: 0
  }
});
