export class CertFullInfo {
  public OGRN: string = "";
  public SNILS: string = "";
  public INN: string = "";
  public Email: string = "";
  public CompanyName: string = "";
  public Position: string = "";
  public LastName: string = "";
  public FirstAndMiddleName: string = "";
  public Locality: string = "";
  public Address: string = "";
  public Region: string = "";

  constructor() {
    /**/
  }
}
