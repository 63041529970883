import Vue from "vue";
import Router from "vue-router";
import cookie from "js-cookie";
import store from "./store";
import { setAccessToken, isAuthenticate } from "@/services/api";

import Login from "@/views/Login.vue";
import {
  CLEAR_APPLICATION_LIST,
  SET_APPLICATIONS_LIST
} from "./constants/mutations-type";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
      beforeEnter: (to, from, next) => {
        if (store.state.updateExists) {
          store.dispatch("refreshApp");
        }

        if (isAuthenticate()) {
          next({ path: "/" });
        } else {
          next();
        }
      }
    },
    {
      path: "/",
      name: "home",
      meta: { requiresAuth: true },
      component: () => import("@/views/Home.vue")
    },
    // {
    //   path: "/archive",
    //   name: "archive",
    //   meta: { requiresAuth: true },
    //   component: () => import("@/views/Home.vue")
    // },
    {
      path: "/profile",
      name: "profile",
      meta: { requiresAuth: true },
      component: () => import("@/views/Profile.vue")
    },
    {
      path: "/application/create",
      name: "create-application",
      meta: { requiresAuth: true },
      component: () => import("@/views/Application.vue")
    },
    {
      path: "/application/:orderId",
      name: "application",
      meta: { requiresAuth: true },
      component: () => import("@/views/Application.vue")
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: (to, from, next) => {
        store.commit(`applicationList/${CLEAR_APPLICATION_LIST}`);
        store.commit(`applicationList/${SET_APPLICATIONS_LIST}`, []);
        store.dispatch("application/clearFromControlsHandler");
        store.dispatch("products/resetProducts");
        store.dispatch("authorization/clear");
        setAccessToken(null);
        cookie.remove("_rt");
        next({ path: "/login" });
      }
    },
    {
      path: "/403",
      name: "403",
      meta: { requiresAuth: true },
      component: () => import("@/views/NotFound.vue"),
      props: { default: true, typeError: "403" }
    },
    {
      path: "*",
      name: "404",
      meta: { requiresAuth: true },
      component: () => import("@/views/NotFound.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (store.state.updateExists) {
    store.dispatch("refreshApp");
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticate()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
