import "../public/crypto-pro-browser-plugin/cadesplugin_api";
import Vue from "vue";

import VModal from "vue-js-modal";
import router from "./router";
import store from "./store";

import App from "./App.vue";

import "./registerServiceWorker";
import "normalize.css";
import "animate.css";
import "@/assets/scss/main.scss";
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { PluginOptions } from 'vue-toastification/dist/types/src/types'

const toastOptions: PluginOptions = {
  draggable: false
}

const isIE = false || !!(document as any).documentMode;

if (isIE) {
  document.body.classList.add("ie");
}

Vue.use(VModal, { dialog: true });
Vue.use(VModal);
Vue.use(Toast,toastOptions);

Vue.config.productionTip=false;

export const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
