import { isEmpty } from "lodash";

export interface IRulesValidation {
  empty?: boolean;
  email?: boolean;
  phone?: boolean;
  inn?: boolean;
  ogrnip?: boolean;
  date?: boolean;
  ogrn?: boolean;
  numeric?: boolean;
  alpha?: boolean;
  uppercase?: boolean;
  minLength?: number;
  maxLength?: number;
}

const reg = {
  email: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,

  phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
};

const ogrnip = (val: number | string): boolean => {
  const value = val.toString();
  if (!/^\d{15}$/.test(value)) {
    return false;
  }
  return (
    // tslint:disable-next-line: radix
    parseInt((Number(value.slice(0, -1)) % 13).toString().slice(-1)) ===
    +value[14]
  );
};

const ogrn = (val: number | string): boolean => {
  const value = val.toString();
  if (!/^\d{13}$/.test(value)) {
    return false;
  }
  return (
    // tslint:disable-next-line: radix
    parseInt((Number(value.slice(0, -1)) % 11).toString().slice(-1)) ===
    +value[12]
  );
};

const inn = (val: number | string): boolean => {
  const value = val.toString();
  const valArr: string[] = value.split("");
  if (!/^\d{10}$/.test(value) && !/^\d{12}$/.test(value)) {
    return false;
  }

  if (
    valArr.length === 10 &&
    +valArr[9] ===
      ((2 * +valArr[0] +
        4 * +valArr[1] +
        10 * +valArr[2] +
        3 * +valArr[3] +
        5 * +valArr[4] +
        9 * +valArr[5] +
        4 * +valArr[6] +
        6 * +valArr[7] +
        8 * +valArr[8]) %
        11) %
        10
  ) {
    return true;
  }

  return (
    valArr.length === 12 &&
    +valArr[10] ===
      ((7 * +valArr[0] +
        2 * +valArr[1] +
        4 * +valArr[2] +
        10 * +valArr[3] +
        3 * +valArr[4] +
        5 * +valArr[5] +
        9 * +valArr[6] +
        4 * +valArr[7] +
        6 * +valArr[8] +
        8 * +valArr[9]) %
        11) %
        10 &&
    +valArr[11] ===
      ((3 * +valArr[0] +
        7 * +valArr[1] +
        2 * +valArr[2] +
        4 * +valArr[3] +
        10 * +valArr[4] +
        3 * +valArr[5] +
        5 * +valArr[6] +
        9 * +valArr[7] +
        4 * +valArr[8] +
        6 * +valArr[9] +
        8 * +valArr[10]) %
        11) %
        10
  );
};

export default (val: string, rules: IRulesValidation) => {
  if (!rules) {
    return true;
  }

  let valid = false;

  if (rules.empty) {
    valid = !isEmpty(val.toString());
  }

  if (rules.email) {
    valid = reg.email.test(val.trim().toLowerCase()) && valid;
  }

  if (rules.phone) {
    valid = reg.phone.test(val) && valid;
  }

  if (rules.inn) {
    valid = inn(val) && valid;
  }

  if (rules.ogrnip) {
    valid = ogrnip(val) && valid;
  }

  if (rules.date) {
    valid = /^[0-9.]*$/.test(val) && valid;
  }

  if (rules.ogrn) {
    valid = ogrn(val) && valid;
  }

  if (rules.numeric) {
    valid = /[0-9]/g.test(val.toString()) && valid;
  }

  if (rules.alpha) {
    valid = /[a-z]/g.test(val.toString()) && valid;
  }

  if (rules.uppercase) {
    valid = /[A-Z]/g.test(val.toString()) && valid;
  }

  if (rules.minLength) {
    valid = rules.minLength <= val.toString().length && valid;
  }

  if (rules.maxLength) {
    valid = rules.maxLength > val.toString().length && valid;
  }

  if (rules.maxLength) {
    valid = rules.maxLength > val.toString().length && valid;
  }

  return valid;
};

export const isFormValid = (controls: any) => {
  const errors: string[] = [];

  Object.keys(controls).forEach((key: string) => {
    const control = { ...controls[key] };
    if (!control.isBlur || (control.required && !control.isValid)) {
      errors.push(key);
    }
  });

  return errors.length === 0;
};

export const isCheckboxValid = (controls: any, id: number) => {
  const result: string[] = [];

  Object.keys(controls).forEach((key: string) => {
    const control = { ...controls[key] };

    if (control.checkboxId === id && control.value) {
      result.push(key);
    }
  });

  return !!result.length;
};
