import { IDefaultUploadFormControls } from "./interfaces/default-upload-form.interface";

export const errorMessage = {
  size: "Размер файла не должен превышать 15 Мб.",
  type: "Неверный формат.",
};

export const defaultUploadFormControls: IDefaultUploadFormControls = {
  application: {
    // img: "/img/doc.svg",
    type: "doc",
    title: "Заявление",
    descr: "",
    isValid: false,
    isTouched: false,
    required: true,
    errors: [],
    accept: ".png, .jpg, .jpeg, .pdf",
    maxFiles: 3,
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg"],
    },
    errorMessage,
  },
  passport: {
    // img: "/img/doc-passport.svg",
    type: "doc-passport",
    title: "Паспорт",
    descr: "",
    isValid: false,
    isTouched: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg"],
    },
    errorMessage,
  },
  snils: {
    // img: "/img/doc-snils.svg",
    type: "doc-snils",
    title: "СНИЛС",
    descr: "",
    isValid: false,
    isTouched: false,
    required: false,
    accept: ".png, .jpg, .jpeg, .pdf",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg"],
    },
    errorMessage,
  },
  foto: {
    // img: "/img/doc-foto.svg",
    type: "doc-foto",
    fromCamera: true,
    title: "Фото заявителя",
    descr: "",
    isValid: false,
    isTouched: false,
    required: false,
    accept: ".png, .jpg, .jpeg, .pdf",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg"],
    },
    errorMessage,
  },
  contract: {
    // img: "/img/doc.svg",
    type: "doc",
    title: "Клиентский договор",
    descr: "",
    isValid: false,
    isTouched: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg"],
    },
    errorMessage,
  },
  // TO-DO: секцию proxy нужно будет убрать
  proxy: {
    // img: "/img/doc.svg",
    type: "doc",
    title: "Доверенность",
    descr: "",
    isValid: false,
    isTouched: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg"],
    },
    errorMessage,
  },
  ///
};
