import { Module } from "vuex";

import { actions } from "./authorization.actions";
import { mutations } from "./authorization.mutations";
import { getters } from "./authorization.getters";

import { AuthorizationState } from "./interfaces/authorization.state";
import { RootState } from "@/store/root.interface";

import { defaultFormControls } from "./login.default";

const namespaced: boolean = true;

export const authorization: Module<AuthorizationState, RootState> = {
  namespaced,
  state: {
    isRecoveryPassword: false,
    isFormValid: true,
    isSubmitActive: false,
    errorMessage: null,
    saveUser: false,
    formControls: { ...defaultFormControls }
  },
  mutations,
  actions,
  getters
};
