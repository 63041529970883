import { Module } from "vuex";

import { defaultFormControls } from "./defaultFormControls";
import { defaultUploadFormControls } from "./defaultUploadFormControls";

import { ApplicationState } from "./interfaces/application-state.interface";
import { RootState } from "@/store/root.interface";

import { mutations } from "./application.mutations";
import { getters } from "./application.getters";
import { actions } from "./application.actions";

import Crypto from "@/services/CryptoProWrapper/CryptoProWrapper";

export const crypto: Crypto = new Crypto();
crypto.load();

const namespaced: boolean = true;

export const application: Module<ApplicationState, RootState> = {
  namespaced,

  state: {
    isFormValid: null,
    isEdit: null,
    isEditUpload: null,
    isSubmited: false,
    isDocumentDownload: false,
    activeTab: 1,
    filesDownload: [],
    fieldsReadonlyKeys: [],
    optionsCountry: [{ value: 193, label: "Россия" }],
    optionsRegion: [],
    certificate: [],
    certRequest: null,
    archived: false,
    externalSourcePkcs10: false,
    formControls: { ...defaultFormControls },
    uploadFormControls: { ...defaultUploadFormControls },
    isUploadForm: false,
    isUploadFormLoaded: true,
    managerId: null,
    orderId: null,
    status: null,
    statusId: null,
    products: [],
    comment: null,
    creationDate: null,
    //changeDate: null,
    isDocumentsCollected: false,
    isCertificateRequested: false,
    currentCertThumbprint: null,
    certIsValid: false,
    errorMessage: null,
    fnsExistingCerts: [],
    optionsFilial: []
  },

  mutations,
  actions,
  getters
};
