import { CertFullInfo } from "@/@types/certificateInfo";
import { isEmpty } from "lodash";

export default class CertificateAdjuster {
  constructor() {
    /* */
  }

  public replaceEmail(certSubjectName: string, newEmail: string): string {
    if (isEmpty(newEmail)) {
      return certSubjectName;
    }

    const dict = this.subjectAsDictionary(certSubjectName);
    dict.set("E", newEmail);

    return this.subjectAsString(dict);
  }

  public Print2Digit(digit: number): string {
    return digit < 10 ? `0${digit}` : digit.toString();
  }
  public GetCertDate(paramDate: Date) {
    const certDate = new Date(paramDate);

    const day = this.Print2Digit(certDate.getUTCDate());
    const month = this.Print2Digit(certDate.getMonth() + 1);
    const year = certDate.getFullYear();
    const hour = this.Print2Digit(certDate.getUTCHours());
    const minutes = this.Print2Digit(certDate.getUTCMinutes());
    const seconds = this.Print2Digit(certDate.getUTCSeconds());

    return `${day}.${month}.${year} ${hour}:${minutes}:${seconds}`;
  }
  public GetCertName(certSubjectName: string) {
    return this.standartExtract(certSubjectName, "CN=");
  }
  public GetIssuer(certIssuerName: string) {
    return this.standartExtract(certIssuerName, "CN=");
  }
  public GetCertInfoString(certSubjectName: string, certFromDate: Date) {
    return `${this.standartExtract(
      certSubjectName,
      "CN="
    )}; Выдан: ${this.GetCertDate(certFromDate)}`;
  }
  public GetFullCertInfo(certSubjectName: string): CertFullInfo {
    const result: CertFullInfo = new CertFullInfo();

    // тёмная магия, делит строку по запятым,
    // но не включает запятые внутри двойных кавычек
    // и преобразует в Map
    const dict = this.subjectAsDictionary(certSubjectName);

    result.OGRN = dict.get("ОГРН") || dict.get("OGRN") || "";
    result.SNILS = dict.get("СНИЛС") || dict.get("SNILS") || "";
    result.INN = dict.get("ИНН") || dict.get("INN") || "";
    result.Email = dict.get("E") || "";
    result.CompanyName = dict.get("O") || "";
    result.Position = dict.get("T") || "";
    result.LastName = dict.get("SN") || "";
    result.FirstAndMiddleName = dict.get("G") || "";
    result.Locality = dict.get("L") || "";
    result.Address = dict.get("STREET") || "";
    result.Region = dict.get("S") || "";

    return result;
  }
  private standartExtract(from: string, what: string): string {
    let certName: string = "";
    const begin: number = from.indexOf(what);
    if (begin >= 0) {
      const end = from.indexOf(", ", begin);
      certName = end < 0 ? from.substr(begin) : from.substr(begin, end - begin);
    }
    return certName;
  }
  private subjectAsDictionary(certSubjectName: string): Map<string, string> {
    return new Map(
      certSubjectName
        .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
        .map(i => i.trim().split(/=(.+)/)) as [[string, string]]
    );
  }
  private subjectAsString(subjectAsDict: Map<string, string>): string {
    const array: string[] = [];

    subjectAsDict.forEach((value: string, key: string) => {
      array.push(`${key}=${value}`);
    });

    return array.join(",");
  }
}
