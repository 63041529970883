import { isArray } from "../utils/array";

export default class EventEmitter {
  private hop: any;

  constructor(protected events: any = {}) {
    this.hop = this.events.hasOwnProperty;
  }

  public on(event: string | string[], handler: () => any) {
    const events = isArray(event)
      ? (event as string[])
      : (event as string).split(" ");

    if (events.length > 1) {
      events.forEach(evt => {
        this.on(evt, handler);
      });
    }

    if (typeof event === "string") {
      if (!this.hop.call(this.events, event)) {
        this.events[event] = [];
      }

      this.events[event].push(handler);
    }
  }

  public emit(event: string | string[], ctx: any) {
    const events = isArray(event)
      ? (event as string[])
      : (event as string).split(" ");

    if (events.length > 1) {
      events.forEach(evt => {
        this.emit(evt, ctx);
      });
    }

    if (typeof event === "string") {
      if (!this.hop.call(this.events, event)) {
        return;
      }

      this.events[event].forEach((item: (ctx: any) => any) => {
        item(ctx || {});
      });
    }
  }
}
