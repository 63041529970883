import { Module } from "vuex";

import { actions } from "./products.actions";
import { mutations } from "./products.mutations";
import { getters } from "./products.getters";

import { ProductsState } from "./interfaces/products.state";
import { RootState } from "@/store/root.interface";

const test: any = [
  {
    id: 3,
    name: "КСКПЭП",
    price: { fl: 499, ip: 499, ur: 499 },
    required: true,
    oids: [
      "1.3.6.1.5.5.7.3.2",
      "1.3.6.1.5.5.7.3.4",
      "1.3.6.1.4.1.311.20.2.2",
      "1.2.643.2.2.34.25"
    ]
  },
  {
    id: 4,
    name: "ЭТП, входящие в состав АЭТП",
    price: { fl: 2499, ip: 2502, ur: 2503 },
    required: false,
    oids: [
      "1.3.6.1.5.5.7.3.2",
      "1.3.6.1.5.5.7.3.4",
      "1.3.6.1.4.1.311.20.2.2",
      "1.2.643.2.2.34.25"
    ]
  },
  {
    id: 55,
    name: "Росреестр тест",
    price: { fl: 500, ip: 1818, ur: 1858 },
    required: false,
    subitemsType: "single",
    subitems: [
      {
        id: 115,
        name: "Судебный пристав-исполнитель",
        oids: ["1.2.643.5.1.24.2.9"]
      },
      {
        id: 118,
        name:
          "Руководитель (заместитель руководителя) федерального органа исполнительной власти",
        oids: ["1.2.643.5.1.24.2.20", "1.2.643.5.1.24.2.30", "1.2.643.100.2.2"]
      },
      {
        id: 145,
        name:
          "Руководитель правоохранительного органа или иное уполномоченное лицо в соответствии с законодательством РФ должностное лицо данного органа",
        oids: ["1.2.643.5.1.24.2.15", "1.2.643.100.2.2"]
      }
    ]
  },
  {
    id: 5,
    name: "ЭТП, входящие в состав АЭТП",
    price: { fl: 2499, ip: 2502, ur: 2503 },
    required: false,
    oids: [
      "1.2.643.3.58.2.1.1",
      "1.2.643.3.8.100.1.42",
      "1.2.643.6.3",
      "1.2.643.6.3.2",
      "1.2.643.6.41"
    ]
  },
  {
    id: 45,
    name: "Росреестр",
    price: { fl: 500, ip: 1818, ur: 1858 },
    required: false,
    subitemsType: "multiple",
    subitems: [
      {
        id: 110,
        name: "Судебный пристав-исполнитель",
        oids: ["1.2.643.5.1.24.2.9"]
      },
      {
        id: 111,
        name:
          "Руководитель (заместитель руководителя) федерального органа исполнительной власти",
        oids: ["1.2.643.5.1.24.2.20", "1.2.643.5.1.24.2.30", "1.2.643.100.2.2"]
      },
      {
        id: 112,
        name:
          "Руководитель правоохранительного органа или иное уполномоченное лицо в соответствии с законодательством РФ должностное лицо данного органа",
        oids: ["1.2.643.5.1.24.2.15", "1.2.643.100.2.2"]
      }
    ]
  }
];

const namespaced: boolean = true;
export const products: Module<ProductsState, RootState> = {
  namespaced,
  state: {
    isValid: true,
    products: [],
    productsSelected: []
  },
  mutations,
  getters,
  actions
};
