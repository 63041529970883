import { MutationTree } from "vuex";
import { defaultUploadFormControls } from "./defaultUploadFormControls";
import { defaultFormControls } from "./defaultFormControls";
import inputField from "./inputField";
import { ApplicationState } from "./interfaces/application-state.interface";
import {
  SET_OPTIONS_COUNTRY,
  SET_OPTIONS_REGION,
  CLEAR_FORM_CONTROLS,
  CLEAR_REGIONS,
  SET_FORM_CONTROL,
  SET_IS_FORM_VALID,
  SET_DISABLED,
  SET_FILES_DOWNLOAD,
  SET_DOCUMENT_DOWNLOAD,
  SET_TYPE_SELECTION,
  SET_ACTIVE_TAB,
  NEXT_ACTIVE_TAB,
  SET_FORM_CONTROLS_FILE,
  SET_CERT_REQUEST_FILE,
  SET_ORDER_ID,
  SET_IS_UPLOAD_FORM,
  SET_IS_EDIT,
  SET_IS_SUBMITED,
  SET_STATUS,
  SET_CREATION_DATE,
  SET_CHANGE_DATE,
  CLEAR_UPLOAD_FORM_CONTROLS,
  SET_IS_EDIT_UPLOAD,
  SET_IS_DOCUMENTS_COLLECTED,
  SET_IS_CERTIFICATE_REQUESTED,
  SET_CURRENT_CERT_THUMBPRINT,
  SET_IS_UPLOAD_FORM_LOADED,
  SET_CERT_IS_VALID,
  SET_ERROR_MESSAGE,
  SET_CERTIFICATE,
  SET_COMMENT,
  SET_STATUS_ID,
  SET_PRODUCTS,
  SET_READONLY_FIELDS_KEY,
  RESET_READONLY_FIELDS_KEY,
  SET_READONLY_FIELDS_KEY_ALL,
  SET_EXTERNAL_SOURCE_PKCS_10,
  SET_ARCHIVED,
  SET_MANAGER_ID,
  SET_OPTIONS_FILIAL,
  SET_FNS_EXISTING_CERTS,
  CLEAN_FNS_EXISTING_CERTS
} from "@/constants/mutations-type";

export const mutations: MutationTree<ApplicationState> = {
  [SET_ORDER_ID](state, val: number | null) {
    state.orderId = val;
  },
  [SET_OPTIONS_COUNTRY](state, country: []) {
    state.optionsCountry = [...country];
  },
  [SET_READONLY_FIELDS_KEY](state, key: string) {
    const result = new Set(state.fieldsReadonlyKeys);

    result.add(key);
    state.fieldsReadonlyKeys = [...result];
  },
  [SET_READONLY_FIELDS_KEY_ALL](state, keys: string[]) {
    state.fieldsReadonlyKeys = [...state.fieldsReadonlyKeys, ...keys];
  },
  [RESET_READONLY_FIELDS_KEY](state, key: string) {
    state.fieldsReadonlyKeys = [];
  },
  [SET_OPTIONS_REGION](state, region: []) {
    state.optionsRegion = [...region];
  },
  [SET_ERROR_MESSAGE](state, val: string | null | string[]) {
    state.errorMessage = val;
  },
  [SET_FORM_CONTROL](state: any, { control, name }: any) {
    state.formControls[name] = { ...control };
  },
  [SET_FORM_CONTROLS_FILE](state: any, { control, name }: any) {
    state.uploadFormControls[name] = { ...control };
  },
  [SET_CERT_REQUEST_FILE](state, val: string) {
    state.certRequest = val;
  },
  [SET_IS_DOCUMENTS_COLLECTED](state, val: boolean) {
    state.isDocumentsCollected = val;
  },
  [SET_IS_CERTIFICATE_REQUESTED](state, val: boolean) {
    state.isCertificateRequested = val;
  },
  [SET_IS_FORM_VALID](state, val: boolean) {
    state.isFormValid = val;
  },
  [SET_ACTIVE_TAB](state, num: number) {
    state.activeTab = num;
  },
  [SET_IS_UPLOAD_FORM](state, val: boolean) {
    state.isUploadForm = val;
  },
  [SET_IS_UPLOAD_FORM_LOADED](state, val: boolean) {
    state.isUploadFormLoaded = val;
  },
  [SET_IS_EDIT](state, val: boolean | null) {
    state.isEdit = val;
  },
  [SET_IS_EDIT_UPLOAD](state, val: boolean | null) {
    state.isEditUpload = val;
  },
  [SET_IS_SUBMITED](state, val: boolean | null){
    state.isSubmited=val;
  },
  [SET_CREATION_DATE](state, date: Date | string | null) {
    state.creationDate = date;
  },
  // [SET_CHANGE_DATE](state, date: Date | string | null) {
  //   state.changeDate = date;
  // },
  [SET_CURRENT_CERT_THUMBPRINT](state, val: string | null) {
    state.currentCertThumbprint = val;
  },
  [SET_CERT_IS_VALID](state, val: boolean) {
    state.certIsValid = val;
  },
  [SET_ARCHIVED](state, val: boolean) {
    state.archived = val;
  },
  [SET_EXTERNAL_SOURCE_PKCS_10](state, val: boolean) {
    state.externalSourcePkcs10 = val;
  },
  [SET_COMMENT](state, val: string | null) {
    state.comment = val;
  },
  [SET_MANAGER_ID](state, payload: number | null) {
    state.managerId = payload;
  },
  [NEXT_ACTIVE_TAB](state) {
    const num = state.activeTab + 1;

    if (num <= 5) {
      state.activeTab = num;
    }
  },
  [CLEAR_FORM_CONTROLS](state) {
    state.formControls = { ...defaultFormControls };
    state.uploadFormControls = { ...defaultUploadFormControls };
    state.isDocumentDownload = false;
    state.filesDownload.length = 0;
    state.isFormValid = null;
    state.isEdit = null;
    state.isEditUpload = null;
    state.managerId = null;
    state.orderId = null;
    state.status = null;
    state.statusId = null;
    state.products = [];
    state.comment = null;
    state.creationDate = null;
    //state.changeDate = null;
    state.activeTab = 1;
    state.certificate = [];
    state.isDocumentsCollected = false;
    state.isCertificateRequested = false;
    state.isUploadForm = false;
    state.isUploadFormLoaded = true;
    state.currentCertThumbprint = null;
    state.certIsValid = false;
    state.errorMessage = null;
  },
  [CLEAR_REGIONS](state) {
    state.optionsRegion = [];
  },
  [CLEAR_UPLOAD_FORM_CONTROLS](state) {
    state.uploadFormControls = { ...defaultUploadFormControls };
  },
  [SET_FILES_DOWNLOAD](state, files: []) {
    state.filesDownload = [...files];
  },
  [SET_CERTIFICATE](state, arr: object[]) {
    state.certificate = [...arr];
  },
  [SET_DOCUMENT_DOWNLOAD](state, val: boolean) {
    state.isDocumentDownload = val;
  },
  [SET_STATUS](state, val: string) {
    state.status = val;
  },
  [SET_STATUS_ID](state, val: number | null) {
    state.statusId = val;
  },
  [SET_PRODUCTS](state, arr: object[]) {
    state.products = [...arr];
  },
  [SET_TYPE_SELECTION](state: any, val: string) {
    state.formControls.typeEntre.value = val;
    Object.keys(state.formControls).forEach(key => {
      if (
        !!(inputField as any)[val] &&
        (key === "typeEntre" || ~(inputField as any)[val].indexOf(key))
      ) {
        state.formControls[key].active = true;
      } else {
        state.formControls[key].active = false;
      }
    });
  },
  [SET_DISABLED](state: any, toggle: boolean = false) {
    Object.keys(state.formControls).forEach(nameControl => {
      const control = { ...state.formControls[nameControl] };

      if (!state.fieldsReadonlyKeys.includes(nameControl)) {
        control.disabled = toggle;
        state.formControls[nameControl] = { ...control };
      }
    });
  },
  [SET_OPTIONS_FILIAL](state, filial: []) {
    state.optionsFilial = [...filial];
  },
  [SET_FNS_EXISTING_CERTS](state, fnsExistingCerts: any[]) {
    state.fnsExistingCerts = JSON.parse(JSON.stringify(fnsExistingCerts));
  },
  [CLEAN_FNS_EXISTING_CERTS](state) {
    state.fnsExistingCerts = [];
  },
};
