import { fetch } from "@/services/api";
import router from "@/router";
import { isObject } from "lodash";
import { app } from "@/main";
import { ActionTree } from "vuex";

import { RootState } from "@/store/root.interface";
import { ProductsState, Product, Subitem } from "./interfaces/products.state";

import {
  SET_PRODUCTS,
  SET_PRODUCTS_SELECTED,
  REMOVE_PRODUCTS_SELECTED,
  RESET_PRODUCT
} from "./constants/mutations-type";

export const actions: ActionTree<ProductsState, RootState> = {
  async fetchProducts({ state, dispatch }) {
    if (!state.products.length) {
      try {
        const {
          status,
          data: { products }
        } = await fetch.post(`api/PreApplication/getProducts`);

        if (status === 200 && products) {
          dispatch("initProducts", { products }, { root: true });
          dispatch("verificationRequiredProducts");
        }
      } catch (error) {
        router.push("/");

        app.$modal.show("dialog", {
          title: "Ошибка",
          text: "Не удалось загрузить список продуктов?",
          buttons: [
            {
              title: "ПРОДОЛЖИТЬ",
              default: true,
              handler: () => {
                app.$modal.hide("dialog");
              }
            }
          ]
        });

        throw error;
      }
    }
  },

  initProducts: {
    root: true,

    handler: (
      { commit },
      {
        products,
        productsSelected = []
      }: {
        products: Product[];
        productsSelected: Array<{
          id: number;
          subitems?: number[];
          price: number;
        }>;
      }
    ) => {
      commit(RESET_PRODUCT);

      productsSelected.forEach(({ id, subitems }) => {
        commit(SET_PRODUCTS_SELECTED, id);
        if (subitems && subitems.length) {
          subitems.forEach(subitemID => {
            commit(SET_PRODUCTS_SELECTED, subitemID);
          });
        }
      });

      commit(SET_PRODUCTS, products);
    }
  },

  getProductsSelected: {
    root: true,

    handler: ({ state }) => {
      const result = new Set();

      state.products.forEach(({ id, subitems }) => {
        if (state.productsSelected.includes(id)) {
          result.add(id);

          if (subitems?.length) {
            subitems.forEach(({ id }) => {
              if (state.productsSelected.includes(id)) {
                result.add(id);
              }
            });
          }
        }
      });

      return [...result];
    }
  },

  getOIDS: {
    root: true,

    handler: ({ state }) => {
      const findOIDS = (
        productsSelected: number[],
        target: Array<Product | Subitem>
      ): string[] => {
        let result: string[] = [];

        productsSelected.forEach(id => {
          const prod: Product | Subitem | undefined = target.find(
            product => product.id === id
          );

          if (prod && prod.oids && prod.oids.length) {
            result = result.concat(
              prod.oids.map(o => (isObject(o) ? o.oid : o))
            );
          } else if (prod && prod.subitems) {
            result = result.concat(findOIDS(productsSelected, prod.subitems));
          }
        });

        return result;
      };

      return [...new Set(findOIDS(state.productsSelected, state.products))];
    }
  },

  verificationRequiredProducts({ state, commit }) {
    state.products.forEach(({ required, id }: Product) => {
      if (required && !state.productsSelected.includes(id)) {
        commit(SET_PRODUCTS_SELECTED, id);
      }
    });
  },

  toggleProductsSelected({ commit }, { id, checked }) {
    if (checked) {
      commit(SET_PRODUCTS_SELECTED, id);
    } else {
      commit(REMOVE_PRODUCTS_SELECTED, id);
    }
  },

  resetProducts({ commit }) {
    commit(RESET_PRODUCT);
  }
};
