import { fetch } from "@/services/api";
import { ActionTree } from "vuex";
import { isEqual } from "lodash";

import { RootState } from "@/store/root.interface";
import { ApplicationList } from "./interfaces/application-list.interface";
import { defaultFilters } from "./default-filters";

import {
  SET_ACTIVE_PAGE,
  SET_SIZE_PAGES,
  SET_ARCHIVE,
  SET_FILTER_CONTROL,
  SET_TOTAL_PAGES,
  SET_APPLICATIONS_LIST,
  SET_IS_LOADED,
  SET_IS_OPEN_FILTER,
  SET_SAVE_FILTER_CONTROL
} from "./constants/mutations-type";

export const actions: ActionTree<ApplicationList, RootState> = {
  changePage({ commit, state, dispatch }, page: number) {
    if (state.activePage !== page) {
      commit(SET_ACTIVE_PAGE, page);
      dispatch("getPreApplication", true);
    }
  },

  changeSizePage({ commit, state, dispatch }, sizePage: number) {
    if (state.sizePage !== sizePage) {
      commit(SET_SIZE_PAGES, sizePage);
      dispatch("getPreApplication", true);
    }
  },

  setArchive({ commit }, val: boolean) {
    commit(SET_ARCHIVE, val);
  },

  setActivePage({ commit }, num: number) {
    commit(SET_ACTIVE_PAGE, num);
  },

  async getPreApplication({ commit, state, getters }) {
    Object.keys(state.saveFilterControls).forEach(name => {
      commit(SET_FILTER_CONTROL, {
        name,
        value: state.saveFilterControls[name]
      });
    });

    try {
      const res = await fetch.get(
        `api/InternalPreApplication/partner/${state.sizePage}/${state.activePage}${getters.getFilter}`
      );

      if (res.status === 200) {
        const { preapplicationList, totalPages, currentPage } = res.data;

        commit(SET_TOTAL_PAGES, totalPages);

        if (currentPage !== state.activePage) {
          commit(SET_ACTIVE_PAGE, currentPage);
        }

        commit(SET_APPLICATIONS_LIST, preapplicationList);
        commit(SET_IS_LOADED, true);
      }
    } catch (err) {
      throw err;
    }
  },

  toggleFilter({ state, commit }, val?: boolean) {
    commit(SET_IS_OPEN_FILTER, val !== undefined ? val : !state.isOpenFilter);
    if (!state.isOpenFilter) {
      if (!isEqual(state.filterControls, state.saveFilterControls)) {
        Object.keys(state.saveFilterControls).forEach(name => {
          commit(SET_FILTER_CONTROL, {
            name,
            value: state.saveFilterControls[name]
          });
        });
      }
    }
  },

  inputHandler({ commit }, evt: any) {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;

    commit(SET_FILTER_CONTROL, {
      name: evt.target.name,
      value
    });
  },

  clearFilter({ commit }) {
    Object.keys(defaultFilters).forEach(name => {
      if (name !== "search") {
        commit(SET_FILTER_CONTROL, {
          name,
          value: defaultFilters[name]
        });

        commit(SET_SAVE_FILTER_CONTROL, {
          name,
          value: defaultFilters[name]
        });
      }
    });
  },

  clearFilterHandler({ state, commit, dispatch }) {
    dispatch("clearFilter");
    commit(SET_IS_OPEN_FILTER, false);
    dispatch("getPreApplication");
  },

  applyFilter({ state, dispatch, commit }) {
    if (!isEqual(state.filterControls, state.saveFilterControls)) {
      Object.keys(state.filterControls).forEach(name => {
        commit(SET_SAVE_FILTER_CONTROL, {
          name,
          value: state.filterControls[name]
        });
      });

      dispatch("getPreApplication");
    }

    commit(SET_IS_OPEN_FILTER, false);
  }
};
