



















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { mapActions, mapState, mapGetters } from "vuex";

import PageLayout from "@/components/layouts/PageLayout.vue";
import Footer from "@/components/Footer.vue";
import Form from "@/components/UI/form/Form.vue";
import Input from "@/components/UI/form/Input.vue";
import Checkbox from "@/components/UI/form/Checkbox.vue";
import Button from "@/components/UI/Button.vue";

@Component({
  computed: {
    ...mapState("authorization", [
      "isRecoveryPassword",
      "isFormValid",
      "errorMessage",
      "isSubmitActive",
      "saveUser"
    ]),
    ...mapGetters("authorization", ["getFormControls"])
  },
  methods: {
    ...mapActions("authorization", [
      "submit",
      "inputHandler",
      "toggleRecoveryPassword",
      "onSaveUser",
      "recoveryPassword"
    ])
  },
  components: {
    Form,
    Input,
    Button,
    Checkbox,
    PageLayout,
    Footer
  }
})
export default class Login extends Vue {
  @Prop({ default: "/" }) public returnUrl!: string;
}
