export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_SIZE_PAGES = "SET_SIZE_PAGES";
export const SET_APPLICATIONS_LIST = "SET_APPLICATIONS_LIST";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const CLEAR_APPLICATION_LIST = "CLEAR_APPLICATION_LIST";
export const SET_IS_LOADED = "SET_IS_LOADED";
export const SET_FILTER_CONTROL = "SET_FILTER_CONTROL";
export const SET_IS_OPEN_FILTER = "SET_IS_OPEN_FILTER";
export const SET_SAVE_FILTER_CONTROL = "SET_SAVE_FILTER_CONTROL";
export const SET_ARCHIVE = "SET_ARCHIVE";
