interface IInputField<T = string[]> {
  fl: T;
  ip: T;
  ur: T;
}

const inputField: IInputField = {
  fl: [
    "surname",
    "name",
    "patronymic",
    "gender",
    "email",
    "phone",
    "dateDoc",
    "birthDate",
    "serialDoc",
    "inn",
    "snils",
    "countryId",
    "regionRealId",
    "cityReal",
    "addressReal",
    "addovert",
    "ksk",
    "kskcom",
    "cryptopro",
    "passportDivision",
    "passportDivisionCode",
    "filialId"
  ],
  ip: [
    "surname",
    "name",
    "patronymic",
    "gender",
    "email",
    "phone",
    "dateDoc",
    "birthDate",
    "serialDoc",
    "snils",
    "inn",
    "ogrnip",
    "countryId",
    "regionRealId",
    "cityReal",
    "addressReal",
    "ksk",
    "kskcom",
    "cryptopro",
    "addovert",
    "phoneOrg",
    "passportDivision",
    "passportDivisionCode",
    "filialId"
  ],
  ur: [
    "surname",
    "name",
    "patronymic",
    "gender",
    "email",
    "phone",
    "dateDoc",
    "birthDate",
    "serialDoc",
    "passportDivision",
    "passportDivisionCode",
    "personInn",
    "inn",
    "snils",
    "ogrn",
    "department",
    "position",
    "countryId",
    "regionLawId",
    "cityLaw",
    "addressLaw",
    "ksk",
    "kskcom",
    "cryptopro",
    "addovert",
    "surnameOrg",
    "nameOrg",
    "patronymicOrg",
    "phoneOrg",
    "fullNameOrg",
    "shortNameOrg",
    "positionDirector",
    "kpp",
    "index",
    "filialId"
  ]
};

export default inputField;
