import { MutationTree } from "vuex";
import { AuthorizationState } from "./interfaces/authorization.state";

import {
  CLEAR_FORM_CONTROLS,
  SET_ERROR_MESSAGE,
  SET_FORM_CONTROL,
  SET_IS_FORM_VALID,
  SET_IS_SUBMIT_ACTIVE,
  SET_SAVE_USER,
  TOGGLE_RECOVERY_PASSWORD
} from "./constants/mutations-type";

export const mutations: MutationTree<AuthorizationState> = {
  [SET_FORM_CONTROL](state, { control, name }: { control: any; name: string }) {
    state.formControls[name] = { ...control };
  },
  [SET_IS_FORM_VALID](state, val: boolean) {
    state.isFormValid = val;
  },
  [SET_ERROR_MESSAGE](state, err: null | string) {
    state.errorMessage = err;
  },
  [SET_SAVE_USER](state, val: boolean) {
    state.saveUser = val;
  },
  [SET_IS_SUBMIT_ACTIVE](state, val: boolean) {
    state.isSubmitActive = val;
  },
  [CLEAR_FORM_CONTROLS](state) {
    state.isRecoveryPassword = false;
    state.isFormValid = true;
    state.isSubmitActive = false;
    state.errorMessage = null;
    state.saveUser = false;
  },
  [TOGGLE_RECOVERY_PASSWORD](state) {
    state.isRecoveryPassword = !state.isRecoveryPassword;
  }
};
