import { MutationTree } from "vuex";
import { RootState } from "./root.interface";

import { SET_REGISTRATION, SET_UPDATE_EXISTS } from "./root.constants";

export const mutations: MutationTree<RootState> = {
  [SET_REGISTRATION](state, payload: ServiceWorkerRegistration | null) {
    state.registration = payload;
  },
  [SET_UPDATE_EXISTS](state, payload: boolean) {
    state.updateExists = payload;
  }
};
