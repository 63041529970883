var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'form-item',
    'form-item-checkbox',
    ("form-item-checkbox-" + _vm.stype),
    {
      invalid: !_vm.valid
    }
  ]},[_c('label',[_c('input',{attrs:{"type":"checkbox","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.state},on:{"change":_vm.onChange}}),_c('span',{staticClass:"checkbox-ghost"}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))])])])}
var staticRenderFns = []

export { render, staticRenderFns }