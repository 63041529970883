

























import { Vue, Component, Prop, Watch, Model } from "vue-property-decorator";

@Component({})
export default class Checkbox extends Vue {
  @Model("change", { type: Boolean }) public readonly checked!: boolean;
  @Prop({ type: Boolean, default: false }) public disabled!: string;
  @Prop({ type: Boolean, default: true }) public valid!: boolean;
  @Prop({ type: String, default: "right" }) public stype!: "right" | "left";
  @Prop({ type: String }) public label!: string;
  @Prop({ type: String }) public name!: string;

  private get state() {
    return this.checked || false;
  }

  public onChange(evt: Event) {
    this.toggle(evt);
  }

  public toggle(evt?: Event) {
    const checked = !this.state;
    this.$emit("change", checked);
    this.$emit("change-event", evt);
  }

  public mounted() {
    if (this.checked && !this.state) {
      this.toggle();
    }
  }

  @Watch("checked")
  private checkedHandler(newValue: boolean) {
    if (newValue !== this.state) {
      this.toggle();
    }
  }
}
