import { Module } from "vuex";

import { actions } from "./application-list.actions";
import { mutations } from "./application-list.mutations";
import { getters } from "./application-list.getters";

import { ApplicationList } from "./interfaces/application-list.interface";
import { RootState } from "@/store/root.interface";

import { defaultFilters } from "./default-filters";

const namespaced: boolean = true;

export const applicationList: Module<ApplicationList, RootState> = {
  namespaced,
  state: {
    applicationsList: [],
    totalPages: null,
    sizePage: 10,
    activePage: 1,
    isLoaded: false,
    isOpenFilter: false,
    isArchive: false,
    filterControls: { ...defaultFilters },
    saveFilterControls: { ...defaultFilters }
  },
  mutations,
  actions,
  getters
};
