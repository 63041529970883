import { FormControl } from "./interfaces/formControl";

export const defaultFormControls: FormControl = {
  email: {
    value: "",
    label: "E-mail",
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      email: true
    }
  },
  password: {
    value: "",
    label: "Пароль",
    type: "password",
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true,
      minLength: 1
    }
  }
};
