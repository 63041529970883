import { GetterTree } from "vuex";

import { AuthorizationState } from "./interfaces/authorization.state";
import { RootState } from "@/store/root.interface";

export const getters: GetterTree<AuthorizationState, RootState> = {
  getFormControls(state: any) {
    if (state.isRecoveryPassword) {
      return {
        email: { ...state.formControls.email }
      };
    }

    return state.formControls;
  }
};
