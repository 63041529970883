/* tslint:disable:no-console */

import { register } from "register-service-worker";
import { notify } from "./services/notify";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration) {
      console.log("Service worker has been registered.");

      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 60);
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("sw:updated");
      document.dispatchEvent(new CustomEvent("sw:updated", { detail: registration }));
    },
    // updated(registration: CustomEventInit<ServiceWorkerRegistration>) {
    //   document.dispatchEvent(
    //     new CustomEvent("sw:updated", { detail: registration })
    //   );
    // },
    offline() {
      notify.show({
        title: "Подключение к интернету не найдено.",
        type: "error"
      });

      console.warn(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
