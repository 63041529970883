import { GetterTree } from "vuex";
import moment from "moment";
import { isEmpty } from "lodash";
import { crypto } from "./index";

import { ApplicationState } from "./interfaces/application-state.interface";
import { RootState } from "@/store/root.interface";

const typeEntre = (val: "fl" | "ip" | "ur", typeSize: "id" | "fullName") => {
  const type = {
    fl: {
      id: 1,
      fullName: "физическое лицо"
    },
    ip: {
      id: 2,
      fullName: "индивидуальный предприниматель"
    },
    ur: {
      id: 3,
      fullName: "юридическое лицо"
    }
  };

  return !isEmpty(val) ? type[val][typeSize] : "";
};

export const getters: GetterTree<ApplicationState, RootState> = {
  getVisibleBodyForm(state) {
    return !isEmpty(state.formControls.typeEntre.value);
  },

  getUpload(state: any) {
    const upload = { ...state.uploadFormControls };
    const addovert = state.formControls.addovert;
    const uploadArr: any = [];

    Object.keys(upload).forEach(nameControl => {
      if (nameControl !== "contract" || +addovert.value === 0) {
        uploadArr.push({
          ...upload[nameControl],
          name: nameControl
        });
      }
    });

    return uploadArr;
  },

  getApplicationInfo(state: any) {
    return {
      status: state.status,
      orderId: state.orderId,
      creationDate: moment(state.creationDate)
        .locale("ru")
        .format("LLL"),
      // changeDate: moment(state.changeDate)
      //   .locale("ru")
      //   .format("LLL"),
      typeEntre: typeEntre(state.formControls.typeEntre.value, "fullName")
    };
  },

  getBrowserPluginEnabled() {
    return crypto.browserPluginEnabled;
  },
  getFnsExistingCerts(state) {
    const certs = state.fnsExistingCerts.map(((cert: any) => ({
        ...cert, issued: cert.issued.replace(/\s\d\d:\d\d:\d\d/, "")
    })))
    return certs;
  }
};
