export const BASE_URL_API = process.env.VUE_APP_BASE_URL_API + "/";
export const BASE_URL_AUTH = process.env.VUE_APP_BASE_URL_AUTH + "/";

// FILTER
export const DATE_FORMAT = "dd.MM.yyyy";

// STYLES
export const MIN_CONTAINER_WIDTH = 1400;
export const MAX_CONTAINER_WIDTH = 1900;
export const CONTAINER_PADDINGS = 30;
